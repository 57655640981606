import { ADD_DEVICE, LISTENING_DEVICE } from './actionTypes'

export const addDevice = device => ({
  type: ADD_DEVICE,
  payload: device
})

export const addListeningDevice = id => ({
  type: LISTENING_DEVICE,
  payload: id
})