import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

export default class DeviceUtils {
  static generateFingerprintUuid = async () => {
    try {
      const storedDeviceUuid = localStorage.getItem('CDS_StoredDeviceUuid')
      if (storedDeviceUuid && storedDeviceUuid !== '') return storedDeviceUuid

      const fpPromise = FingerprintJS.load({ token: 'qWrdj7r6z8RlqD9KlAwy' })
      // const fpPromise = FingerprintJS.load()
      const fp = await fpPromise
      const result = await fp.get({ extendedResult: true })
      const devicefingerprintid = result.visitorId
      localStorage.setItem('CDS_StoredDeviceUuid', devicefingerprintid)

      return devicefingerprintid
    } catch (e) {
      throw Error(e)
    }
  }
}
