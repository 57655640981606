// user
export const ADD_USER = 'ADD_USER'
export const ADD_EXTENSION = 'ADD_EXTENSION'

// device
export const ADD_DEVICE = 'ADD_DEVICE'
export const LISTENING_DEVICE = 'LISTENING_DEVICE'

// settings
export const SHOW_MENU = 'SHOW_MENU'
export const DISPLAY_AD = 'DISPLAY_AD'
export const PDF_AD = 'PDF_AD'
export const FULL_SCREEN_HEIGHT = 'FULL_SCREEN_HEIGHT'
export const HIDE_IMAGES = 'HIDE_IMAGES'
