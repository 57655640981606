import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Alert, AlertTitle } from "@material-ui/lab";
import CountrLoader from "../../utils/CountrLoader";
import { useSearchParams } from "react-router-dom";

import { addUser } from "../../store/actions/user";
import { addDevice, addListeningDevice } from "../../store/actions/device";
import { hideImages } from "../../store/actions/settings";
import { AppInstances } from "../../utils/counterSdkInstance";
import DeviceUtils from "../../utils/DeviceUtils";

import "./Login.css";

const mapDispatchToProps = (dispatch) => ({
  addUser: (user) => dispatch(addUser(user)),
  addDevice: (device) => dispatch(addDevice(device)),
  addListeningDevice: (id) => dispatch(addListeningDevice(id)),
  hideImages: (val) => dispatch(hideImages(val)),
});

const Login = (props) => {
  const [isLoading, setIsloading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberPassword, setRememberPassword] = useState(true);
  const [searchParams] = useSearchParams();

  const isReady = async (username, password) => {
    try {
      const countr = await AppInstances.getCountrSdk();
      const user = await countr.register(username, password);
      props.addUser(user);

      if (!localStorage.getItem("access_token")) {
        countr.refresh();
      }

      countr.once("registered", (token) => {
        localStorage.setItem("token_type", token.token_type);
        localStorage.setItem("access_token", token.access_token);
        localStorage.setItem("refresh_token", token.refresh_token);
        countr.setToken({
          access_token: token.access_token,
          refresh_token: token.refresh_token,
        });
      });

      return countr;
    } catch (error) {
      handleRequestError(error);
    }
  };

  const login = async () => {
    setUsername(username);
    setPassword(password);
    localStorage.setItem("CDS-RememberPassword", rememberPassword);
    setIsloading(true);

    try {
      const countr = await isReady(username, password);
      loadFingerPrintAndLogin(countr);
    } catch (error) {
      handleRequestError(error);
    }
  };

  const autologin = async () => {
    const autologin = localStorage.getItem("CDS-RememberPassword");
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");

    if (autologin && access_token && refresh_token) {
      const countr = await AppInstances.getCountrSdk();

      countr.setToken({
        access_token,
        refresh_token,
      });

      try {
        const user = await countr.me.read();
        props.addUser(user);
        loadFingerPrintAndLogin(countr);
      } catch (error) {
        handleRequestError(error);
      }
    }
  };

  const loadFingerPrintAndLogin = async (countr) => {
    const devicefingerprintid = await DeviceUtils.generateFingerprintUuid();
    try {
      // CDS devices have cds- before their fingerprint
      const registeredDevice = await countr.devices.readOne.detailed(
        `cds-${devicefingerprintid}`
      );
      handleSuccess(registeredDevice);
    } catch (error) {
      if (error && error.status && error.status === 404) {
        // If the device is not registered add the new generated fp in to the devices.uuid prop
        props.addDevice({ uuid: `cds-${devicefingerprintid}` });
        props.history("/registration", {
          state: {
            deviceId: searchParams.get("deviceId"),
            deviceName: searchParams.get("deviceName"),
            storeId: searchParams.get("storeId"),
          },
        });
      } else {
        handleRequestError(error);
      }
    }
  };

  const handleSuccess = (d) => {
    setIsloading(false);
    const device = { ...d, store: d.store._id };
    props.addDevice(device);
    const deviceId = localStorage.getItem("device");
    if (device.settings) props.hideImages(device.settings.hideImages);

    // If device was passed in the query string, use that - otherwise use the stored one or register
    if (searchParams.get("deviceId")) {
      props.addListeningDevice(searchParams.get("deviceId"));
      props.history("/main");
    } else if (deviceId && deviceId.length) {
      props.addListeningDevice(deviceId);
      props.history("/main");
    } else {
      props.history("/registration");
    }
  };

  const handleRequestError = (error) => {
    // console.error(error)
    setIsloading(false);
    setErrorMsg(error.message);
  };

  const handleRememerPassword = () => {
    const newValue = !rememberPassword;
    setRememberPassword(newValue);
    localStorage.setItem("CDS-RememberPassword", newValue);
  };

  const closeNotification = () => {
    setIsloading(false);
    setErrorMsg("");
  };

  useEffect(() => {
    if (searchParams.get("accessToken")) {
      //Set access token locally
      localStorage.setItem("access_token", searchParams.get("accessToken"));
      //Set refresh token
      localStorage.setItem("refresh_token", searchParams.get("accessToken"));
      //Set remember password
      localStorage.setItem("CDS-RememberPassword", true);
    }
    const auto = JSON.parse(localStorage.getItem("CDS-RememberPassword"));

    if (!!auto) {
      setIsloading(true);
      autologin();
    }
  }, []);

  return (
    <div className={props.classes.login}>
      <div className="spacer" />
      {isLoading && <CountrLoader action={"Authenticating"} />}
      <Grid container className={props.classes.root} justifyContent="center">
        <div className="card-center">
          <Grid item xs={12} className={props.classes.grid}>
            <TextField
              id="username"
              label="Username"
              className={props.classes.textField}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>account_circle</Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={props.classes.grid}>
            <TextField
              id="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              className={props.classes.textField}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>lock</Icon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <Icon>visibility</Icon>
                      ) : (
                        <Icon>visibility_off</Icon>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className="grid-space-up">
            <FormGroup row className="login-form-control-row">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberPassword}
                    onChange={handleRememerPassword}
                    style={{ color: "#318ed5" }}
                  />
                }
                label="Remember Password"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} className={props.classes.grid}>
            <Button
              variant="contained"
              className={props.classes.btnTextField}
              onClick={login}
            >
              {isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <font>Login</font>
              )}
            </Button>
          </Grid>
        </div>
      </Grid>
      <Snackbar
        open={errorMsg.length > 1}
        autoHideDuration={5000}
        onClick={closeNotification}
      >
        <Alert variant="filled" severity="error" onClose={closeNotification}>
          <AlertTitle>Login error</AlertTitle>
          {errorMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

const styles = {
  login: {
    marginTop: 50,
    // padding: 15
  },
  root: {
    flexGrow: 1,
    marginTop: 15,
    textAlign: "center",
  },
  grid: {
    direction: "row",
    justify: "center",
    alignItems: "center",
  },
  textField: {
    width: 300,
  },
  btnTextField: {
    width: 300,
    color: "#FFF",
    marginTop: 30,
    backgroundColor: "#318ed5",
    "&:hover, &:focus, &:active": {
      backgroundColor: "#318ed5",
    },
  },
};

const LoginConnected = connect(null, mapDispatchToProps)(Login);
export default withStyles(styles)(LoginConnected);
