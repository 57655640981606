import { ADD_USER, ADD_EXTENSION } from './../actions/actionTypes'

const initialState = {
  user: {},
  extensions: []
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER:
      return {
        ...state,
        user: action.payload
      }
    case ADD_EXTENSION:
      return {
        ...state,
        extensions: state.extensions.concat(action.payload)
      }
    default:
      return state
  }
}

export default userReducer
