import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { addUser } from "../../store/actions/user";
import { displayAd } from "../../store/actions/settings";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";

import { Alert, AlertTitle } from "@material-ui/lab";

import { CartUtils } from "@countr/utils";

import { AppInstances } from "../../utils/counterSdkInstance";

import Resume from "../Resume";
import AdDisplay from "../AdDisplay";
import ChangeStoreModal from "../DeviceRegistration/ChangeStoreModal";
import CountrLoader from "../../utils/CountrLoader";

import defaultImg from "../../assets/icon.svg";
import "./Main.css";

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    device: state.device,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (user) => dispatch(addUser(user)),
    displayAd: (display) => dispatch(displayAd(display)),
  };
};

const Main = (props) => {
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0.0);
  const [subTotal, setSubTotal] = useState(0.0);
  const [discountRate, setDiscountRate] = useState(0.0);
  const [discount, setDiscount] = useState(0.0);
  const [tax, setTax] = useState(0.0);
  const [currency, setCurrency] = useState("€");
  const [cartName, setCartName] = useState("Cart");
  const [transactionCreate, setTransactionCreate] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [customer, setCustomer] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [adChange, setAdChange] = useState(true);
  const [updatedPhotoAd, setUpdatedPhotoAd] = useState(false);
  const [openChangeStoresModal, setOpenChangeStoresModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const cleanState = () => {
    setCart([]);
    setTotal(0.0);
    setSubTotal(0.0);
    setDiscountRate(0.0);
    setDiscount(0.0);
    setTax(0.0);
    setCurrency("€");
    setCartName("Cart");
    setTransactionCreate(false);
    setCustomer({});
  };

  const handleCloseModal = (showNotification) => {
    if (showNotification === true) {
      setOpenModal(false);
      setTransactionCreate(true);
      setShowNotification(true);
      setNotificationTitle("Receipt sent to email!");
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    } else {
      setOpenModal(false);
      setTransactionCreate(true);
    }
  };

  const handleNewAdd = () => {
    setAdChange(!adChange);
  };

  const init = () => {
    const user = props.user.user;

    if (!user) {
      return;
    }

    const device = props.device.listeningDevice;

    AppInstances.getCountrSdk().then((socket) => {
      socket.ws.on(`d${device}:transaction.created`, (message) => {
        // console.log('--- TRANSACTION CREATED ---')

        socket.ws.transactions
          .readOne((message || {}).transaction || message)
          .then((transaction) => {
            // if (transaction.device === props.device.listeningDevice) {
            // Payment info modal will be displayed for 60 seconds or until another cart is created
            setTimeout(() => {
              setOpenModal(false);
            }, 60000);

            setTransaction(transaction);
            setOpenModal(true);
            cleanState();

            setTransactionCreate(true);
            setShowNotification(true);
            setNotificationTitle("Payment succeeded!");
            setTransactionId(message);

            setTimeout(() => {
              setShowNotification(false);
            }, 5000);
            // }
          });
      });

      socket.ws.on(`m${user._id}:device.updated`, (message) => {
        if (message === props.device.device._id) {
          props.displayAd(true);
          setUpdatedPhotoAd(true);
          setAdChange(true);
        }
      });

      socket.ws.on(`d${device}:cart.updated`, (message) => {
        // console.log('--- CART UPDATED ---')
        if (message && message.cart) {
          socket.ws.carts
            .readOne(message.cart)
            .then((response) => {
              const products = new Set();

              setCustomer(response.customer);
              if (response.items.length > 0) {
                setOpenModal(false);
                for (const product in response.items) {
                  products.add({
                    _id: response.items[product].product._id,
                    amount: response.items[product].amount,
                    name: response.items[product].product.name,
                    categories: response.items[product].product.categories
                      .map((c) => c.name)
                      .join(),
                    price:
                      response.items[product].product.current_variant.price,
                    tax: response.items[product].product.current_variant.tax
                      .rules[0].rate,
                    discount: response.items[product].product.discount,
                    image: response.items[product].product.image
                      ? response.items[product].product.image
                      : "./../../icon.svg",
                    color: response.items[product].product.categories[0].color,
                    note:
                      response.items[product].note !== undefined
                        ? response.items[product].note
                        : "",
                    addons: response.items[
                      product
                    ].product.current_addons.concat({
                      name: response.items[product].note,
                      _id: new Date().getTime(),
                    }),
                    itemTotalPrice: CartUtils.calculateCartEntryPrice(
                      response.items[product]
                    ),
                  });

                  setCart([...products]);
                  setCurrency(response.currency.symbol);
                  setCartName(
                    response.extras.deviceCartName.trim().toUpperCase()
                  );
                  setTotal(response.total);
                  setSubTotal(response.sub_total);
                  setTax((response.total - response.sub_total).toFixed(2));
                  setDiscountRate(response.discount);
                  setDiscount(calculateCartDiscount(response));
                }
              } else {
                cleanState();
              }
            })
            .catch((e) => {
              console.error(e);
            });
        } else {
          setTransactionCreate(false);
        }
      });

      socket.ws.devices.update(props.device.device._id, {
        settings: props.settings,
      });
    });
  };

  const getTotalNumberOfItems = () => {
    return [...cart].reduce((acc, product) => {
      return acc + product.amount;
    }, 0);
  };

  const calculateCartDiscount = (cart) => {
    if (!cart.reduction?.numeric && cart.discount && cart.discount > 0) {
      const discount = getCartTotalNoDiscount(cart);
      return parseFloat(discount).toFixed(2);
    } else if (cart.reduction?.numeric) {
      return parseFloat(cart.reduction.numeric).toFixed(2);
    } else {
      return 0.0;
    }
  };

  const getCartTotalNoDiscount = (cart) => {
    const discountPerc = 1 - cart.discount;
    const originalAmount = cart.total / discountPerc;

    return originalAmount - cart.total || 0;
  };

  const loadCurrentCart = async () => {
    try {
      const countr = await AppInstances.getCountrSdk();
      const lastUpdatedCart = await countr.carts.read({
        filter: { device: props.device.listeningDevice },
        limit: 1,
        sort: "-updated_at",
      });
      const lastCart = lastUpdatedCart[0] || false;
      const products = new Set();

      if (!!lastCart?.items?.length) {
        for (var product in lastCart.items) {
          if (lastCart.items[product]) {
            products.add({
              _id: lastCart.items[product].product._id,
              amount: lastCart.items[product].amount,
              name: lastCart.items[product].product.name,
              categories: lastCart.items[product].product.categories
                .map((c) => c.name)
                .join(),
              price: lastCart.items[product].product.current_variant.price,
              tax: lastCart.items[product].product.current_variant.tax.rules[0]
                .rate,
              discount: lastCart.items[product].product.discount,
              image: lastCart.items[product].product.image
                ? lastCart.items[product].product.image
                : "./../../icon.svg",
              color: lastCart.items[product].product.categories[0].color,
              note:
                lastCart.items[product].note !== undefined
                  ? lastCart.items[product].note
                  : "",
              addons: lastCart.items[product].product.current_addons.concat({
                name: lastCart.items[product].note,
                _id: new Date().getTime(),
              }),
              itemTotalPrice: CartUtils.calculateCartEntryPrice(
                lastCart.items[product]
              ),
            });

            setCart([...products]);
            setCurrency(lastCart.currency.symbol);
            setCartName(lastCart.extras.deviceCartName.trim().toUpperCase());
            setTotal(lastCart.total);
            setSubTotal(lastCart.sub_total);
            setTax((lastCart.total - lastCart.sub_total).toFixed(2));
            setDiscountRate(lastCart.discount);
            setDiscount(calculateCartDiscount(lastCart));
            setLoading(false);
          }
        }
      } else {
        cleanState();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeNotification = () => {
    setShowNotification(false);
  };

  const handlePaperWidth = () => {
    const windowWidth = window.innerWidth;
    if (!props.settings.displayAd) {
      return "100%";
    } else if (props.settings.displayAd && windowWidth >= 1280) {
      return "58.2%";
    } else if (
      props.settings.displayAd &&
      windowWidth < 1280 &&
      windowWidth >= 955
    ) {
      return "50%";
    } else if (props.settings.displayAd && windowWidth < 955) {
      return "41.6%";
    }
  };

  const handleChangeStore = (e) => {
    if (e.detail === 3) {
      setOpenChangeStoresModal(true);
    }
  };

  const handleCloseStoresModal = () => {
    setOpenChangeStoresModal(false);
  };

  useEffect(() => {
    init();
    loadCurrentCart();
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      {loading ? (
        <CountrLoader action={"Loading current cart"} />
      ) : (
        <Badge
          className="cart-icon-badge"
          badgeContent={getTotalNumberOfItems()}
          color="secondary"
          onClick={handleChangeStore}
        >
          <Tooltip open={true} placement="left" title={cartName}>
            <Icon className="cart-icon">shopping_cart</Icon>
          </Tooltip>
        </Badge>
      )}

      <Grid container spacing={0}>
        <Grid
          item
          xs={props.settings.displayAd ? 4 : 12}
          sm={props.settings.displayAd ? 5 : 12}
          md={props.settings.displayAd ? 6 : 12}
          lg={props.settings.displayAd ? 7 : 12}
        >
          <List className="list">
            {cart.map((product, i) => (
              // making product key as {_id-discount}, so if have some duplicate product (one with discount and other without)
              <div key={`${product._id}-${i}`}>
                <ListItem button className="listItem">
                  {props.settings.hideImages ? (
                    ""
                  ) : product.image.indexOf("http") >= 0 ? (
                    <img
                      alt="default img"
                      src={product.image}
                      className="avatar"
                    />
                  ) : (
                    <img alt="img" src={defaultImg} className="avatar" />
                  )}

                  <ListItemText
                    primary={product.name}
                    secondary={product.addons.map((addon) => (
                      <font key={addon._id}>
                        {addon.name}{" "}
                        {addon.price !== undefined
                          ? `(${currency} ${addon.price.toFixed(2)}) x ${
                              addon.amount
                            }`
                          : ``}
                        <br />
                      </font>
                    ))}
                  />
                  <div className="productDetails">
                    <ListItemText
                      primary={`${currency} ${product.itemTotalPrice.toFixed(
                        2
                      )}`}
                      secondary={`x ${product.amount}`}
                    />
                    <ListItemText
                      className="productDiscount"
                      secondary={
                        product.discount > 0 &&
                        (product.discount * 100).toFixed(2) + "%"
                      }
                    />
                  </div>
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
          <div className="fixedItem" />
          <Paper
            className="paper"
            elevation={4}
            style={{ width: handlePaperWidth() }}
          >
            <div className="subPaper">
              <Typography component="p">
                <font className="cartFooterDetailsLabel">Discount</font>
                <strong className="cartFooterDetails">
                  {currency} {discount || "0.00"}{" "}
                  {!!discount &&
                    !!discountRate &&
                    `(${(discountRate * 100).toFixed(2)}%)`}
                </strong>
              </Typography>
              <Typography component="p">
                <font className="cartFooterDetailsLabel">Subtotal</font>
                <strong className="cartFooterDetails">
                  {currency} {subTotal.toFixed(2)}
                </strong>
              </Typography>
              <Typography component="p">
                <font className="cartFooterDetailsLabel">Taxes</font>
                <strong className="cartFooterDetails">
                  {currency} {tax || "0.00"}
                </strong>
              </Typography>
            </div>
            <Divider />
            <Typography type="headline" component="h3" className="cartTotal">
              <font className="cartFooterDetailsLabel cartFooterDetailsLabelTotal">
                Total
              </font>
              <strong className="cartFooterDetails cartFooterDetailsLabelTotal">
                {currency}
                {total.toFixed(2)}
              </strong>
            </Typography>
          </Paper>
        </Grid>

        {props.settings.displayAd && (
          <Grid
            item
            xs={8}
            sm={7}
            md={6}
            lg={5}
            style={{
              backgroundColor: props.settings.isPdf ? "#eeeeee" : "black",
              height: "100vh",
              objectFit: `cover`,
            }}
          >
            <AdDisplay
              handleNewAdd={handleNewAdd}
              adChange={adChange}
              updatedPhotoAd={updatedPhotoAd}
            />
          </Grid>
        )}
      </Grid>

      {openModal && (
        <Resume
          open={openModal}
          transaction={transaction}
          handleCloseModal={handleCloseModal}
          customer={transaction.customer ? transaction.customer.email : ""}
          stateCustomer={customer}
          transactionCreate={transactionCreate}
          transactionId={transactionId}
        />
      )}

      <ChangeStoreModal
        open={openChangeStoresModal}
        handleClose={handleCloseStoresModal}
      />

      <Snackbar
        open={showNotification}
        autoHideDuration={5000}
        onClick={closeNotification}
      >
        <Alert
          variant="filled"
          severity="success"
          onClose={() => {
            closeNotification();
          }}
        >
          <AlertTitle>{notificationTitle}</AlertTitle>
        </Alert>
      </Snackbar>
    </div>
  );
};

const MainConnected = connect(mapStateToProps, mapDispatchToProps)(Main);
export default MainConnected;
