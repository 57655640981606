import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import Login from './components/Login'
import Main from './components/Main'
import DeviceRegistration from './components/DeviceRegistration'

import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'

import FullscreenListener from './utils/FullscreenListener'
import { AppInstances } from './utils/counterSdkInstance'
import ErrorUtils from './utils/ErrorUtils'
import countrLogo from './assets/countr_white.png'
import './App.css'
import { withRouter } from './wrappers/routeWrappers'

const mapStateToProps = state => {
  return {
    user: state.user,
    device: state.device,
    storeLogo: state.device.device.store
  }
}

const App = props => {
  const location = useLocation()
  const [fullScreenApp, setFullScreenApp] = useState(false)
  const [displayLogo, setDisplayLogo] = useState(countrLogo)
  const [altImgName, setAltImgName] = useState('Countrhq logo')
  
  const history = useNavigate()

  const handleOpenFullScreen = () => {
    if (location.pathname === '/main' || location.pathname === '/registration') {
      setFullScreenApp(true)
    }
  }

  const handleCloseFullScreen = () => {
    setFullScreenApp(false)
  }

  const isAuth = () => {
    let result = false
    if (props.user.user._id) {
      result = true
    }
    return result
  }

  const hasDeviceandUser = () => {
    let result = false
    if (isAuth() && props.device.listeningDevice.length) {
      result = true
    }
    return result
  }

  const handleShowUserLogo = async store => {
    try {
      const countr = await AppInstances.getCountrSdk()
      const selectedStore = await countr.stores.readOne(store)

      if (!!selectedStore?.store_logo?.length) {
        setDisplayLogo(selectedStore.store_logo)
        setAltImgName(selectedStore?.name || 'Merchant logo')
      }
    } catch (error) {
     ErrorUtils.logErrorToCountr(error)
    }
  }

  useEffect(() => {
    if (props.storeLogo?.length) {
      handleShowUserLogo(props.storeLogo)
    }
  }, [props.storeLogo])

  return (
    <div>
      {fullScreenApp && (
        <FullscreenListener
          handleCloseFullScreen={handleCloseFullScreen}
          fullScreenApp={fullScreenApp}
        />
      )}

      <AppBar position="fixed" className="app-bar">
        <Typography type="h6" color="inherit" onClick={handleOpenFullScreen}>
          <img alt={altImgName} className="logoImg" src={displayLogo} />
        </Typography>
      </AppBar>

      <Routes>
        <Route exact path="/" element={<Login history={history} {...props} />} />
        <Route
          path="/main"
          element={hasDeviceandUser() ? <Main history={history} {...props} /> : <Navigate to="/" />}
        />
        <Route
          path="/registration"
          element={
            isAuth() ? <DeviceRegistration history={history} {...props} /> : <Navigate to="/" />
          }
        />
        <Route path="*" element={<Login history={history} {...props} />} />
      </Routes>
    </div>
  )
}

const AppConnected = connect(mapStateToProps, null)(App)
export default withRouter(AppConnected)
