import React, { useState, useEffect } from "react";
import * as CountrRest from "../../utils/countrREST";

import ReactPlayer from "react-player";
import { Document, Page, pdfjs } from "react-pdf";

import { connect } from "react-redux";
import { displayAd, displayPdfAd } from "../../store/actions/settings";
import { addDevice } from "../../store/actions/device";

import Collapse from "@material-ui/core/Collapse";
import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
import Zoom from "@material-ui/core/Zoom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    device: state.device,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    displayAd: (display) => dispatch(displayAd(display)),
    addDevice: (device) => dispatch(addDevice(device)),
    displayPdfAd: (isPdf) => dispatch(displayPdfAd(isPdf)),
  };
};

const pdfStylingHeight = window.innerHeight * 0.12;
const pdfStylingWidth = window.innerWidth * 0.02;

const AdDisplay = (props) => {
  const [adType, setAdType] = useState("");
  const [adUrl, setAdUrl] = useState("");
  const [activeImage, setActiveImage] = useState("");
  const [newActive, setNewActive] = useState(false);
  const [activeAnimation, setActiveAnimation] = useState("");
  const [activePdfUrl, setActivePdfUrl] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfRotationTime, setPdfRotationTime] = useState(0);

  const handleDeviceAds = async () => {
    const storedDevice = props.device.device._id;
    props.handleNewAdd();
    if (storedDevice) {
      const device = await CountrRest.getDevice(storedDevice);
      props.addDevice(device);
      let time;
      if (
        device &&
        device.options &&
        device.options.adInfo &&
        device.options.adInfo.adType === "Video" &&
        device.options.adInfo.adUrl
      ) {
        setAdType(device.options.adInfo.adType);
        setAdUrl(device.options.adInfo.adUrl);
        props.displayAd(true);
        props.displayPdfAd(false);
      } else if (
        device &&
        device.options &&
        device.options.adInfo &&
        device.options.adInfo.adType === "Photos" &&
        device.options.adInfo.photoAdUrls
      ) {
        if (props.updatedPhotoAd) {
          // window.location.reload(true)
        } else {
          setAdType(device.options.adInfo.adType);
          props.displayAd(true);
          time = device.options.adInfo.rotationTime;
          setActiveImage(device.options.adInfo.photoAdUrls[0]);
          setActiveAnimation("Collapse");
          setNewActive(true);
          props.displayPdfAd(false);
          if (device.options.adInfo.photoAdUrls.length > 1) {
            handleImageRotation(device.options.adInfo.photoAdUrls, time);
          }
        }
      } else if (
        device &&
        device.options &&
        device.options.adInfo &&
        device.options.adInfo.adType === "PDF" &&
        device.options.adInfo.pdfAdUrl
      ) {
        setAdType(device.options.adInfo.adType);
        setPdfRotationTime(device.options.adInfo.pdfRotationTime);
        props.displayAd(true);
        setActivePdfUrl(device.options.adInfo.pdfAdUrl);
        props.displayPdfAd(true);
      } else {
        props.displayPdfAd(false);
        props.displayAd(false);
      }
    }
  };

  const handleChangeAnimation = () => {
    const randomSelected = Math.floor(Math.random() * 5);
    if (randomSelected === 0) {
      setActiveAnimation("Collapse");
    } else if (randomSelected === 1) {
      setActiveAnimation("Fade");
    } else if (randomSelected === 2) {
      setActiveAnimation("Grow");
    } else if (randomSelected === 3) {
      setActiveAnimation("Slide");
    } else {
      setActiveAnimation("Zoom");
    }
  };

  const handleImageRotation = (images, time) => {
    const count = images.length;
    let imageNo = 0;
    if (imageNo > 0) {
      setActiveImage("");
    }
    setInterval(
      () => {
        imageNo++;
        if (imageNo > count - 1) {
          imageNo = 0;
        }
        setNewActive(true);
        setActiveImage(images[imageNo]);
      },
      time ? time : 10000
    );

    setInterval(
      () => {
        setNewActive(false);
        handleChangeAnimation();
      },
      time ? time - 800 : 9200
    );
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    let currentPage = 1;
    if (numPages > 1) {
      setInterval(() => {
        currentPage++;
        if (currentPage > numPages) {
          currentPage = 1;
        }
        setPageNumber(currentPage);
      }, pdfRotationTime);
    }
  };

  useEffect(() => {
    if (props.adChange) {
      handleDeviceAds();
    }
    return () => clearInterval();
  }, [props.adChange]);

  return (
    <div style={{ overflow: "hidden" }}>
      {adType === "PDF" && (
        <div
          style={{
            backgroundColor: "#eeeeee",
            marginTop:
              props.settings.fullScreenHeight > 0
                ? props.settings.fullScreenHeight * 0.18
                : pdfStylingHeight,
            marginLeft: window.innerWidth > 1450 ? pdfStylingWidth : 0,
          }}
        >
          <Document file={activePdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
      )}
      {adType === "Video" && (
        <div
          style={{ backgroundColor: "black", marginTop: 64, height: "auto" }}
        >
          <ReactPlayer
            url={adUrl}
            playing={true}
            width="auto"
            height="93.3vh"
            loop={true}
            volume={0}
          />
        </div>
      )}
      {adType === "Photos" && activeAnimation === "Collapse" && (
        <Collapse in={newActive}>
          <img
            alt="device-ad"
            // height: '95vh'
            style={{
              marginTop: 64,
              height: props.settings.fullScreenHeight > 0 ? "95vh" : "93.3vh",
              width: `100%`,
              transition: `opacity 1s ease-in-out`,
            }}
            src={activeImage}
          />
        </Collapse>
      )}
      {adType === "Photos" && activeAnimation === "Fade" && (
        <Fade in={newActive}>
          <img
            alt="device-ad"
            style={{
              marginTop: 64,
              height: props.settings.fullScreenHeight > 0 ? "95vh" : "93.3vh",
              width: `100%`,
              transition: `opacity 1s ease-in-out`,
            }}
            src={activeImage}
          />
        </Fade>
      )}
      {adType === "Photos" && activeAnimation === "Grow" && (
        <Grow in={newActive}>
          <img
            alt="device-ad"
            style={{
              marginTop: 64,
              height: props.settings.fullScreenHeight > 0 ? "95vh" : "93.3vh",
              width: `100%`,
              transition: `opacity 1s ease-in-out`,
            }}
            src={activeImage}
          />
        </Grow>
      )}
      {adType === "Photos" && activeAnimation === "Slide" && (
        <Slide in={newActive}>
          <img
            alt="device-ad"
            style={{
              marginTop: 64,
              height: props.settings.fullScreenHeight > 0 ? "95vh" : "93.3vh",
              width: `100%`,
              transition: `opacity 1s ease-in-out`,
            }}
            src={activeImage}
          />
        </Slide>
      )}
      {adType === "Photos" && activeAnimation === "Zoom" && (
        <Zoom in={newActive}>
          <img
            alt="device-ad"
            style={{
              marginTop: 64,
              height: props.settings.fullScreenHeight > 0 ? "95vh" : "93.3vh",
              width: `100%`,
              transition: `opacity 1s ease-in-out`,
            }}
            src={activeImage}
          />
        </Zoom>
      )}
    </div>
  );
};

const AdDisplayConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdDisplay);
export default AdDisplayConnected;
