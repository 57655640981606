import { ADD_DEVICE, LISTENING_DEVICE } from './../actions/actionTypes'

const initialState = {
  device: {},
  listeningDevice: ''
}

const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEVICE:
      return {
        ...state,
        device: action.payload
      }
    case LISTENING_DEVICE:
      return {
        ...state,
        listeningDevice: action.payload
      }
    default:
      return state
  }
}

export default deviceReducer
