import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import Divider from '@material-ui/core/Divider'

import DeviceRegistration from '.'

import '../Main/Main.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

const ChangeStoreModal = props => {
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle id='alert-dialog-slide-title' className='modal-width'>
        {'Change connected store and device'}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DeviceRegistration modal handleClose={props.handleClose} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={props.handleClose} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeStoreModal
