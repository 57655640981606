import { AppInstances } from './counterSdkInstance'
import store from '../index'

export default class ErrorUtils {
  static logErrorToCountr = async obj => {
    const user = store.getState()?.user?.user
    const device = store.getState()?.device?.device
    
    const errorObj = {
      message: `${obj?.msg}, users: ${user.username}, _ID: ${user._id}, device id: ${device._id}`,
      user: user._id,
      store: device.store._id,
      device: device._id,
      stack: JSON.stringify(obj?.stack),
      date: new Date().toISOString(),
      info: obj?.info
    }

    AppInstances.logError(errorObj)
  }
}
