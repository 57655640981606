import { ADD_USER, ADD_EXTENSION } from './actionTypes'

export const addUser = user => ({
  type: ADD_USER,
  payload: user
})

export const addExtension = extension => ({
  type: ADD_EXTENSION,
  payload: extension
})
