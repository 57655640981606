const api = process.env.REACT_APP_API_SERVER

let auth = `Authorization:Bearer ${localStorage.getItem('access_token')}`

const headers = {
  Accept: 'application/json',
  Authorization: auth,
  'Content-Type': 'application/json'
}

export const getEmployees = () => {
  return fetch(`${api}/employees/`, { headers }).then(response => response.json())
}

export const getDevice = (id) => {
  return fetch(`${api}/devices/${id}`, { headers }).then(response => response.json())
}

export const getStore = (id) => {
  return fetch(`${api}/stores/${id}`, { headers }).then(response => response.json())
}

