import { SHOW_MENU, DISPLAY_AD, PDF_AD, FULL_SCREEN_HEIGHT, HIDE_IMAGES } from './actionTypes'

export const showMenu = () => ({
  type: SHOW_MENU
})

export const displayAd = (display) => ({
  type: DISPLAY_AD,
  payload: display
})

export const displayPdfAd = (isPdf) => ({
  type: PDF_AD,
  payload: isPdf
})

export const setFullScreenHeight = (screen) => ({
  type: FULL_SCREEN_HEIGHT,
  payload: screen
})

export const hideImages = (hideImages) => ({
  type: HIDE_IMAGES,
  payload: hideImages
})