import { SHOW_MENU, DISPLAY_AD, PDF_AD, FULL_SCREEN_HEIGHT, HIDE_IMAGES } from './../actions/actionTypes'

const initialState = {
  menu: false,
  displayAd: false,
  hideImages: false,
  isPdf: false,
  fullScreenHeight: 0
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MENU:
      return {
        ...state,
        menu: !state.menu
      }
    case DISPLAY_AD:
      return {
        ...state,
        displayAd: action.payload
      }
    case PDF_AD:
      return {
        ...state,
        isPdf: action.payload
      }
    case FULL_SCREEN_HEIGHT:
      return {
        ...state,
        fullScreenHeight: action.payload
      }
    case HIDE_IMAGES:
      return {
        ...state,
        hideImages: action.payload
      }
    default:
      return state
  }
}

export default settingsReducer
