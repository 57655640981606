import React, { useState, useEffect } from "react";
import { AppInstances } from "../../utils/counterSdkInstance";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";

const StoreList = (props) => {
  const [storesList, setStoresList] = useState([]);
  const [allDevices, setAlldevices] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [storeSelected, setStoreSelected] = useState("");
  const [deviceSelected, setDeviceSelected] = useState("");
  const [searchDevice, setSearchDevice] = useState("");
  const [filteredDeviceList, setFiletedDeviceList] = useState([]);

  const init = async () => {
    try {
      const user = props.user;
      const devicesNumber = user !== null ? user.device_count : 400;
      const countr = await AppInstances.getCountrSdk();
      const stores = await countr.stores.read();
      const devices = await countr.devices.read({
        limit: devicesNumber,
        sort: "-created_at",
      });

      setAlldevices(devices);
      setStoresList(stores);

      if (
        props?.device?.store &&
        stores.findIndex((store) => store._id === props.device.store) >= 0
      ) {
        setStoreSelected(props.device.store);

        const filteredDevices = devices.filter(
          (device) =>
            device.store === props.device.store &&
            device._id !== props.device._id
        );

        if (filteredDevices) {
          setDevicesList(filteredDevices);
          setFiletedDeviceList(filteredDevices);
        }

        const listeningDevice = props.listeningDevice;

        if (listeningDevice.length) {
          const dev = devices.find((d) => d._id === listeningDevice);

          setDeviceSelected(listeningDevice);
          setSearchDevice(dev.name);
          const list = filteredDevices.filter(
            (device) =>
              device.name.toLowerCase().indexOf(dev.name.toLowerCase()) >= 0
          );
          setFiletedDeviceList(list);
        }
      }
      props.handleCloseLoading();
    } catch (error) {
      console.error(error);
      props.handleCloseLoading();
    }
  };

  const handlerStoreSelect = () => (event) => {
    const filteredDevices = allDevices.filter(
      (device) => device.store === event.target.value
    );

    props.handlerStoreSelect(event.target.value);
    setStoreSelected(event.target.value);
    setDevicesList(filteredDevices);
    setFiletedDeviceList(filteredDevices);
    setDeviceSelected("");
    setSearchDevice("");
  };

  const handlerClickDevice = (id) => {
    props.handlerDeviceSelect(id);
    setDeviceSelected(id);
    const dev = devicesList.find((d) => d._id === id);
    setSearchDevice(dev.name);
  };

  const handleChange = () => (event, text) => {
    const value = text ? event : event.target.value;
    setSearchDevice(value);
    if (value === 0) {
      setFiletedDeviceList(devicesList);
    } else {
      const list = devicesList.filter(
        (device) => device.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
      );
      setFiletedDeviceList(list);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className={props.classes.deviceRegistration}>
      <Grid container className={props.classes.root}>
        <Grid item xs={12} className={props.classes.grid}>
          <FormControl className={props.classes.textField}>
            <InputLabel>Select your store</InputLabel>
            <Select
              value={storeSelected}
              onChange={handlerStoreSelect("storeSelected")}
              className={props.classes.left}
            >
              {storesList.length &&
                storesList.map((store) => {
                  return (
                    <MenuItem key={store._id} value={store._id}>
                      {store.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {storeSelected.length > 0 && (
        <Grid container className={props.classes.root}>
          <Grid item xs={12}>
            <TextField
              id="searchDevice"
              label="Type device's name to search"
              value={searchDevice}
              onChange={handleChange("searchDevice")}
              margin="normal"
              style={{ width: 300 }}
            />
            <List
              component="nav"
              className={props.classes.list}
              style={props.modal ? { maxHeight: 300 } : {}}
            >
              {filteredDeviceList.length > 0 && <Divider />}

              {filteredDeviceList.map((device) => (
                <ListItem
                  button
                  key={device._id}
                  divider={true}
                  onClick={() => handlerClickDevice(device._id)}
                >
                  <ListItemIcon>
                    <Icon>smartphone</Icon>
                  </ListItemIcon>
                  <ListItemText primary={device.name} />
                  {deviceSelected === device._id && (
                    <ListItemSecondaryAction>
                      <Icon>check</Icon>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const styles = {
  deviceRegistration: {
    // marginTop: 50,
    paddingBottom: 15,
  },
  root: {
    flexGrow: 1,
    marginTop: 15,
    textAlign: "center",
    justify: "center",
    alignItems: "center",
  },
  grid: {
    direction: "column",
    justify: "center",
    alignItems: "center",
  },
  textField: {
    width: 300,
  },
  left: {
    textAlign: "left",
  },
  list: {
    maxWidth: 300,
    margin: "auto",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: "#fff",
    maxHeight: 350,
    overflow: "auto",
  },
};

export default withStyles(styles)(StoreList);
